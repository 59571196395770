import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { QueryClientProvider } from '@tanstack/react-query';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { Auth0ProviderWithNavigate } from './contexts/Auth0ProviderWithNavigate';
import { AuthContextProvider } from './contexts/AuthContext';
import { queryClient } from './hooks/api/react-query';
import useTheme from './hooks/useTheme';
import './i18n';
import { store } from './redux/store';
import routes from './routes';
import createTheme from './theme';

function App() {
  const content = useRoutes(routes);

  const { theme } = useTheme();

  return (
    <HelmetProvider>
      <Helmet titleTemplate="%s | JST" defaultTitle=" JST Admin Dashboard">
        <script type="text/javascript">
          {` window.zESettings = {
    webWidget: {
      color: {
        theme: '#197D8E',
        launcherText: '#fafafa',
        header: '#197D8E',
      },
      position: { horizontal: 'left', vertical: 'bottom' },
      offset: {
        vertical: '30px',
        horizontal: '25px',
      }
    
    }
  }
  `}
        </script>
        <script
          id="ze-snippet"
          src="https://static.zdassets.com/ekr/snippet.js?key=3c13e7a6-de92-415c-b4a9-0a8b11da8a60"
        ></script>
      </Helmet>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <MuiThemeProvider theme={createTheme(theme)}>
            <Auth0ProviderWithNavigate>
              <AuthContextProvider>{content}</AuthContextProvider>
            </Auth0ProviderWithNavigate>
          </MuiThemeProvider>
        </Provider>
      </QueryClientProvider>
    </HelmetProvider>
  );
}

export default App;
