import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Grid as MuiGrid,
  Typography as MuiTypography,
  Card as MuiCard,
  CardContent,
  CardActionArea,
  Box,
  Button,
} from '@mui/material';
import { spacing, SpacingProps } from '@mui/system';
import { PageComponentType } from '../../types/pageComponent';
import PageComponent from './components/HomePageItems';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import VideoIcon from '@mui/icons-material/OndemandVideo';
import { t } from 'i18next';
import { useUsers } from '../../hooks/api/useUsers';

const Card = styled(MuiCard)(spacing);

const Grid = styled(MuiGrid)(spacing);

interface TypographyProps extends SpacingProps {
  component?: string;
}
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

const AboutIcon = styled.span`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(2)};

  svg {
    width: 14px;
    height: 14px;
  }
`;
const Icon = styled.img`
  height: 50px;
`;

const StyledCard = styled(Card)`
  height: 200px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.08);
  background-color: ${process.env.REACT_APP_BUILD === 'staging'
    ? '#083833'
    : '#0b313f'};

  &:hover {
    background-color: #12536b;
  }
`;

const StyledCardContent = styled(CardContent)`
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Header = styled(Typography)`
  color: #ffffff;
`;

const Paragraph = styled(Typography)`
  color: #ffffff;
`;

const StyledVideoButton = styled(Button)`
  &:focus {
    outline: 2px solid #0b313f;
  }
`;

const Section = ({ title, icon, subtext, url }: PageComponentType) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <StyledCard mb={6}>
      <CardActionArea
        onClick={() => {
          url.includes('https' || 'http')
            ? window.open(url, '_blank', 'noopener,noreferrer')
            : navigate(url);
        }}
      >
        <StyledCardContent>
          <Header variant="h3">{t(title)}</Header>
          <Grid mb={2}>
            <Paragraph variant="body1" sx={{ fontSize: '16px' }}>
              {t(subtext)}
            </Paragraph>
          </Grid>
          <Grid item>
            <AboutIcon>
              <Icon src={icon} alt={`${t(title)} icon`} />
            </AboutIcon>
          </Grid>
        </StyledCardContent>
      </CardActionArea>
    </StyledCard>
  );
};

function HomePage() {
  const { accounts: accountList } = useUsers();
  const [account, setAccount] = useState('');
  const { user } = useAuth0();

  useEffect(() => {
    /** Used for fetching accountname for welcome banner */
    const currentAccount =
      user?.[`https://api.jstscifree.com/app_metadata`]?.account;
    for (let [key, value] of Object.entries(accountList || {})) {
      if (value === currentAccount) {
        setAccount(key);
        break;
      }
    }
  }, [user, accountList]);

  return (
    <React.Fragment>
      <Helmet title="Pages" />
      <Grid
        container
        sx={{
          background: '#F3F6F9',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box padding={12}>
          <Typography
            variant="h1"
            sx={{ color: '#0B313F', fontSize: 18, fontWeight: 500 }}
          >
            {t('home.welcomeBanner.header')}
          </Typography>
          <Typography
            variant="h2"
            sx={{ color: '#0B313F', fontSize: 20, fontWeight: 600 }}
          >
            {account}
          </Typography>
        </Box>
        <Box padding={12}>
          <a
            href="https://youtu.be/_z9OWQbXyQo"
            rel="noreferrer"
            target="_blank"
          >
            <StyledVideoButton
              variant="contained"
              disableElevation
              size="large"
              startIcon={<VideoIcon />}
            >
              {t('home.welcomeBanner.link')}
            </StyledVideoButton>
          </a>
        </Box>
      </Grid>
      <Grid
        container
        spacing={30}
        justifyContent="space-between"
        padding={10}
        mt={10}
        sx={{ background: '#ffffff' }}
      >
        {PageComponent() &&
          PageComponent().map((page) => (
            <Grid item xs={12} sm={6} lg={4} xl={4} key={page.icon}>
              <Section
                title={page.title}
                icon={page.icon}
                subtext={page.subtext}
                url={page.url}
              />
            </Grid>
          ))}
      </Grid>
    </React.Fragment>
  );
}

export default HomePage;
