import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useAuthContext } from '../../contexts/AuthContext';
import { useGetAccessToken } from '../useGetAccessToken';
import { queryClient } from './react-query';

const baseUrl = process.env.REACT_APP_API_URL || 'https://api.jstscifree.com';

type AddUserArgs = {
  data: {
    email: string;
  };
};

type CreateAccountArgs = {
  data: {
    name: string;
    path: string;
  };
};

export const useUsers = () => {
  const { getToken } = useGetAccessToken();
  const { account } = useAuthContext();
  const { user } = useAuth0();

  const currentAccount =
    user?.[`https://api.jstscifree.com/app_metadata`]?.account;
  const scifreeAdmin = currentAccount === 'admin';

  const { data: users, isLoading: isLoadingUsers } = useQuery({
    queryKey: ['users'],
    queryFn: async () => {
      const token = await getToken();
      const response = await axios.get(`${baseUrl}/users`, {
        headers: scifreeAdmin
          ? {
              Authorization: `Bearer ${token}`,
              'X-SciFree-Context': account,
            }
          : {
              Authorization: `Bearer ${token}`,
            },
      });
      return response.data;
    },
  });

  const { mutateAsync: deleteUserMutation } = useMutation({
    mutationFn: async (email: string) => {
      const token = await getToken();
      await axios.delete(`${baseUrl}/users/${email}`, {
        headers: scifreeAdmin
          ? {
              Authorization: `Bearer ${token}`,
              'X-SciFree-Context': account,
            }
          : {
              Authorization: `Bearer ${token}`,
            },
      });
    },
    onSuccess: () => {
      // Invalidate and refetch users after deletion
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
  });

  const { mutateAsync: addUserMutation } = useMutation({
    mutationFn: async ({ data }: AddUserArgs) => {
      const token = await getToken();
      await axios.post(`${baseUrl}/users`, data, {
        headers: scifreeAdmin
          ? {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
              'X-SciFree-Context': account,
            }
          : {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
      });
    },
    onSuccess: () => {
      // Invalidate and refetch users after addition
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
  });

  const { data: accountData, isLoading: isLoadingAccount } = useQuery({
    queryKey: ['account'],
    queryFn: async () => {
      const token = await getToken();
      const response = await axios.get(`${baseUrl}/accounts`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    },
    enabled: !!account,
  });

  const { mutateAsync: createAccountMutation } = useMutation({
    mutationFn: async ({ data }: CreateAccountArgs) => {
      const token = await getToken();
      return axios.post(`${baseUrl}/accounts`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'X-SciFree-Context': account,
        },
      });
    },
    onSuccess: () => {
      // Invalidate and refetch account data after creation
      queryClient.invalidateQueries({ queryKey: ['account'] });
    },
  });

  return {
    users,
    isLoadingUsers,
    deleteUser: deleteUserMutation,
    addUser: addUserMutation,
    accounts: accountData || {},
    isLoadingAccount,
    createAccount: createAccountMutation,
  };
};
